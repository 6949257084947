body {
  font-size: 14px;
  font-weight: 400;
  padding-top: 100px;
  color: #3c3c3c;
  font-family: 'Poppins', sans-serif !important;
}

.wrapper-content {
  padding: 40px 50px 50px 150px;
  width: 100%;
}
.wrapper-content-c {
  padding: 40px 50px 50px 50px;
  width: 100%;
}

.scrollable-element {
  scrollbar-color: red yellow !important;
}

.text-note {
  color: #828282;
  font-size: 12px;
  margin: 5px 0px 0px 10px;
  font-weight: 300;
  letter-spacing: -0.24px;
}

.bg-1 {
  width: 132px;
  height: 132px;
  top: 150px;
  right: 424px;
  background-image: url('../public/Images/img-bg/c1.png');
}

.bg-2 {
  width: 290px;
  height: 430px;
  top: 265px;
  right: 0px;
  background-image: url('../public/Images/img-bg/c2.png');
}

.bg-3,
.bg-9 {
  width: 169px;
  height: 169px;
  bottom: -17px;
  left: 300px;
  background-image: url('../public/Images/img-bg/c3.png');
}

.bg-4 {
  position: absolute;
  width: 328px;
  height: 265px;
  bottom: -71px;
  right: -38px;
  background-image: url('../public/Images/img-bg/c4.png');
}

.bg-4-1 {
  position: absolute;
  width: 328px;
  height: 265px;
  bottom: -71px;
  right: -37px;
  background-image: url('../public/Images/img-bg/c4.png');
}

.bg-5,
.bg-10 {
  position: absolute;
  width: 173px;
  height: 173px;
  bottom: 220px;
  right: 290px;
  background-image: url('../public/Images/img-bg/c5.png');
}
.bg-5-1 {
  position: absolute;
  width: 173px;
  height: 173px;
  bottom: 220px;
  right: 200px;
  background-image: url('../public/Images/img-bg/c5.png');
}

.bg-6,
.bg-8 {
  position: absolute;
  width: 94px;
  height: 94px;
  top: 0px;
  right: 105px;
  background-image: url('../public/Images/img-bg/c6.png');
}

.bg-7 {
  position: absolute;
  width: 303px;
  height: 303px;
  top: 195px;
  right: -173px;
  background-image: url('../public/Images/img-bg/c7.png');
}

.bg-7-1 {
  position: absolute;
  width: 303px;
  height: 303px;
  top: 195px;
  right: -300px;
  background-image: url('../public/Images/img-bg/c7.png');
}

.bg-8 {
  position: absolute;
  width: 94px;
  height: 94px;
  top: -9px;
  right: -8px;
  background-image: url('../public/Images/img-bg/c6.png');
}

.bg-9 {
  width: 185px;
  height: 185px;
  bottom: -5px;
  left: 228px;
  background-image: url('../public/Images/img-bg/c9.png');
}

.bg-10 {
  bottom: 140px;
  right: 365px;
}

.bg-11 {
  position: absolute;
  width: 377px;
  height: 271px;
  bottom: -71px;
  right: -38px;
  background-image: url('../public/Images/img-bg/c10.png');
}

.bg-12 {
  position: absolute;
  width: 403px;
  height: 265px;
  bottom: -71px;
  right: 307px;
  background-image: url('../public/Images/img-bg/c11.png');
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: transparent;
}

@media screen and (max-width: 768px) {
  .wrapper-content {
    padding: 40px 20px 50px 120px !important;
    width: 100%;
  }
}

.image-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.image-button img {
  display: block;
  width: 35px;
  height: auto;
  transition: transform 0.3s ease;
}

.image-button:hover img {
  transform: scale(1.1);
}

.App-logo-excel {
  margin-top: 30px;
  height: 30px;
  float: right;
  margin-right: 25px;
  cursor: pointer;
}

.download-button {
  height: 0rem;
  width: 0rem;
  background-color: transparent;
}

.excel-export-button {
  background-image: url('../public/Images/icon-excel.png') !important; /* Adjust the path based on your project structure */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
  background-color: transparent !important; /* Force background to be transparent */
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.excel-button {
  border: none; /* No borders */

  padding: 10px 0px; /* Padding around the text */
  text-align: center; /* Centered text */
  color: transparent;
  cursor: pointer; /* Pointer cursor on hover */
}

.white-button {
  color: white;
}
.react-datepicker-popper {
  z-index: 30 !important;
}

.modal-stuffing-wdith {
  --bs-modal-width: 50rem;
}

.modal-inner-margin {
  margin-bottom: 5px;
  margin-top: 20px;
}
.react-datepicker-popper {
  z-index: 30 !important;
}
