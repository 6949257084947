.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.bt-icon-attr {
  background-image: url('../../../../public/Images/icon-non-attr.png');
}

.bt-icon-en-cour {
  background-image: url('../../../../public/Images/icon-en-cour.png');
}

.bt-icon-en-retard {
  background-image: url('../../../../public/Images/icon-en-retard.png');
}

.bt-icon-valid {
  background-image: url('../../../../public/Images/icon-valid.png');
}

.bt-icon-smart-with-review {
  background-image: url('../../../../public/Images/icon-smart-with-review.png');
}

.bt-icon-smart {
  background-image: url('../../../../public/Images/icon-smart.png');
}

.bt-icon-list {
  position: relative;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  background-size: contain;
}

.bg-orange {
  background-color: #ff8d4b;
}

.bg-blue {
  background-color: #0064d2;
}

.bg-darkorange {
  background-color: #ee3900;
}

.bg-green {
  background-color: #43b929;
}

.bg-pink {
  background-color: #ae388b;
}

.bg-grey {
  background-color: #868b8e;
}

.box-circle {
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}

.box-header-icon {
  width: 32px;
  height: 38px;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.label-text {
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3c3c3c;
  line-height: 25px;
  margin: 15px auto;
  font-weight: 500;
}
.box-pd {
  padding: 16.5px 10px;
  cursor: pointer;
}

.box-pd:hover > .label-text,
.box-pd:hover span i {
  color: #fff;
}

.box-pd:hover span i {
  font-size: 15px !important;
}

.box-pd:hover > .box-header-icon > .bt-icon-list > .icon-sm i {
  font-size: 32px !important;
}

/*box orange*/

.box-pd-orange:hover span {
  background-color: #ffb185 !important;
}

.box-pd-orange:hover {
  background-color: #ffb185;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-orange {
  background-color: #fff !important;
  color: #ff8d4b;
}

/*box blue*/

.box-pd-blue:hover span {
  background-color: #7fb1e8 !important;
}

.box-pd-blue:hover {
  background-color: #7fb1e8;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-blue {
  background-color: #fff !important;
  color: #0064d2;
}

/*box dark orange*/

.box-pd-darkorange:hover span {
  background-color: #f79f83 !important;
}

.box-pd-darkorange:hover {
  background-color: #f79f83;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-darkorange {
  background-color: #fff !important;
  color: #ee3900;
}

/*box green*/

.box-pd-green:hover span {
  background-color: #a1dc94 !important;
}

.box-pd-green:hover {
  background-color: #a1dc94;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-green {
  background-color: #fff !important;
  color: #43b929;
}

/*box pink*/

.box-pd-pink:hover span {
  background-color: #f9b4f6 !important;
}

.box-pd-pink:hover {
  background-color: #f9b4f6;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-pink {
  background-color: #fff !important;
  color: #ae388b;
}

/*box grey*/

.box-pd-grey:hover span {
  background-color: #b9b7bd !important;
}

.box-pd-grey:hover {
  background-color: #b9b7bd;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-grey {
  background-color: #fff !important;
  color: #868b8e;
}

.box-pd-blue:hover > .box-header-icon > .bt-icon-en-cour {
  background-image: url('../../../../public/Images/icon-en-cour-white.png');
  transform: scale(1.2);
}

.box-pd-orange:hover > .box-header-icon > .bt-icon-attr {
  background-image: url('../../../../public/Images/icon-none-attr-white.png');
  transform: scale(1.2);
}

.box-pd-darkorange:hover > .box-header-icon > .bt-icon-en-retard {
  background-image: url('../../../../public/Images/icon-en-retard-white.png');
  transform: scale(1.2);
}

.box-pd-green:hover > .box-header-icon > .bt-icon-valid {
  background-image: url('../../../../public/Images/icon-valid-white.png');
  transform: scale(1.2);
}

.box-pd-pink:hover > .box-header-icon > .bt-icon-smart-with-review {
  background-image: url('../../../../public/Images/icon-smart-with-review-white.png');
  transform: scale(1.2);
}

.box-pd-grey:hover > .box-header-icon > .bt-icon-smart {
  background-image: url('../../../../public/Images/icon-smart-white.png');
  transform: scale(1.2);
}

.card {
  display: flex;
  flex-direction: column;
  height: 200px;
}
.faq-card {
  display: flex;
  flex-direction: column;
}

.text-container {
  height: 2em; /* Set the height to two lines of text */
  line-height: 1em; /* Set the line-height to match the font size */
  overflow: hidden; /* Hide any text that exceeds the container height */
}

.faq-container {
  padding: 2em;
}

.faq-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 1em;
}

.faq-description {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 2em;
}

.faq-sections {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2em;
  width: 100%;
}

.faq-section {
  flex: 1;
  min-width: 200px;
  text-align: left;
}

.faq-section h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.faq-section ul {
  list-style: none;
  padding: 0;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  margin-bottom: 1em;
}

.bt-download-circle {
  cursor: pointer;
  /* Add your icon styling here */
}

.see-all {
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
