.box-wrapper-gray {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 15px;
}

.box-txt {
  display: flex;
  font-size: 16px;
  color: #3c3c3c;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #bdbdbd;
  margin-bottom: 15px;
  align-items: center;
}

.box-txt div:first-child {
  flex: 1;
}

.box-txt strong {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
}

.i-13 {
  font-size: 13px;
}

.label-text-h {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 15px;
}

.label-text-h strong {
  font-weight: 500;
}

.box-review-text {
  overflow: auto;
  position: relative;
  border: none;
}

.box-list-text-gap-regeneration {
  height: 220px;
  overflow: auto;
  position: relative;
  border: none;
}

.box-list-text {
  height: 284px;
  overflow: auto;
  position: relative;
  border: none;
}

.box-review-text .box-list-text::-webkit-scrollbar {
  width: 3px;
}

.box-review-text .box-list-text::-webkit-scrollbar-thumb {
  background-color: #ffe426; /* Adjust the scrollbar thumb color */
  border-radius: 20px; /* Adjust the border radius of the scrollbar thumb */
}

.box-review-text .box-list-text ul,
.tab-link-editor ul,
.tab-icon-editor ul {
  margin: 0px;
  padding: 0px;
}

.box-review-text .box-list-text li,
.tab-link-editor li,
.tab-icon-editor li {
  list-style: none;
}

.box-review-text.box-list-text li,
.sub-header-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.box-motcle {
  height: 222px;
}

.txt-gray {
  color: #ccc;
}

.box-graph {
  margin: 15px 0px;
}

.box-wrapper-editor {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.tab-link-editor {
  padding: 0px 10px;
  border-bottom: 1px solid #bdbdbd;
}

.tab-link-editor li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.tab-icon-editor {
  display: flex;
  font-size: 12px;
  padding: 0px 5px;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #bdbdbd;
}

.tab-icon-editor > .box-list-icon:last-child {
  border-right: 0px;
}

.box-list-icon {
  border-right: solid 1px #ddd;
  padding: 0px 5px;
}

.box-list-icon > .dropdown > .dropdown-custom,
.box-list-icon > .bx-icon > .dropdown > .dropdown-custom {
  font-size: 13px;
  border: 0px !important;
  height: 34px !important;
  padding: 0px !important;
}

.box-list-icon > .dropdown > .dropdown-custom span,
.box-list-icon > .bx-icon > .dropdown > .dropdown-custom span {
  right: 0px !important;
}

.box-list-icon > .bx-icon {
  display: inline-flex;
  font-size: 14px;
  padding: 0px 5px;
  height: 35px !important;
  align-items: center;
  justify-content: center;
}

.label-box {
  display: inline-flex;
  background-color: #c8cace;
  padding: 5px 8px;
  border-radius: 3px;
  height: 28px;
  align-items: center;
}

.box-textarea {
  padding: 10px;
  position: relative;
  height: 430px;
}

.box-textarea textarea {
  resize: none;
  font-weight: 400;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  height: 400px;
}

.box-textarea > .form-control:focus {
  box-shadow: none !important;
  border: 0px !important;
  text-align: left;
}

.box-botom-flex {
  display: flex;
  margin-top: 15px;
  font-size: 16px;
}

.box-botom-flex div:first-child {
  flex: 1;
}

.box-list-dropdown {
  display: inline-flex;
  margin-top: 10px;
  align-items: center;
}

.box-detail-text {
  margin-top: 15px;
  font-size: 16px;
  margin-bottom: 15px;
}

.tox-tinymce {
  border: 0px !important;
  border-radius: 12px !important;
  font-family: 'Poppins', sans-serif !important;
}

.tox .tox-mbtn__select-label,
.tox .tox-tbtn--bespoke .tox-tbtn__select-label,
.tox .tox-tbtn,
.tox .tox-tbtn svg {
  color: #3c3c3c !important;
}

.tox .tox-tbtn svg {
  fill: #3c3c3c !important;
}

.tox .tox-statusbar__resize-handle,
.tox-statusbar {
  display: none !important;
}

textarea:focus,
input:focus {
  outline: 0;
}
.box-transparent {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.keywordStuffedRed {
  color: red !important;
}
.keywordStuffedPurple {
  color: purple !important;
}

.vertical-layout {
  flex-direction: column !important;
  align-items: flex-start;
}

.vertical-layout > button {
  width: 100%;
  margin-bottom: 8px; /* Adjust spacing as needed */
}
