.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr input {
  border-right: 0px !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl input {
  border-left: 0px !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  padding-left: 0px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding-right: 40px;
  font-size: 16px;
  text-align: center;
}

.number-wrapper {
  position: relative;
}
