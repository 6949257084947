.bt-submit-table {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 50px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
  margin: auto;
}

.bt-delete-circle {
  background-color: #f5f5f5;
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
}

.bt-delete-circle {
  background-image: url('../../../../public/Images/icon-delete-default.png');
}

.bt-delete-circle:hover {
  background-image: url('../../../../public/Images/icon-delete-hover.png');
}

.header-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #3c3c3c;
  margin-bottom: 15px;
}

.tooltip,
.tooltip > .tooltip-inner {
  background-color: #fff !important;
  font-size: 16px !important;
}

.dropdown-custom {
  height: 40px !important;
  padding: 8px !important;
  border-radius: 6px !important;
  border: solid 1px #e0e0e0 !important;
  background-color: #fff;
  color: #3c3c3c;
  text-align: left;
}

.dropdown-custom::after {
  display: none;
}

.dropdown-custom span {
  display: block;
  color: #3c3c3c;
  position: absolute;
  right: 10px;
  background-color: white;
  top: 9px;
  padding: 0px 5px;
}

.dropdown-custom:focus {
  box-shadow: none !important;
}

.dropdown-custom:hover {
  color: #3c3c3c;
  background-color: #fff !important;
}

.dropdown-custom.dropdown-toggle.show {
  background-color: #fff !important;
  color: #3c3c3c !important;
}

.dropdown-menu {
  min-width: 100% !important;
  z-index: 3 !important;
}

.dropdown-menu li a {
  font-size: 14px;
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  background: none !important;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

table.table-lists {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  vertical-align: middle;
}

table.table-lists th {
  background-color: #fff;
  border-left: 0px;
  text-align: center;
  color: #828282;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  letter-spacing: -0.02em;
  border-bottom: solid 1px #eee;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-commandes td {
  color: #3c3c3c !important;
  vertical-align: middle;
}

table.table-commandes thead th:first-child {
  width: 26%;
}

table.table-commandes thead th:nth-child(2) {
  width: 20%;
}

span.bt-circle-wh {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 2.5px;
}

table.table-lists tr.bg-yellow td,
table.table-lists tr:hover td {
  background-color: rgba(255, 228, 38, 0.4) !important;
}

table.table-lists tr.bg-yellow td > span.bt-circle-wh,
span.bt-circle-wh:hover {
  background-color: white;
  display: inline-flex;
  width: 32px;
  height: 32px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

table.table-lists td {
  padding: 0px 15px 0px 15px !important;
  height: 42px;
  vertical-align: middle;
}

.row-page {
  padding: 0px 5px 0px 15px;
}

.box-mt {
  margin-top: 50px;
}

.box-collapse img {
  width: 43px;
  cursor: pointer;
}

table.table-attribees thead th:first-child {
  width: 23%;
}

table.table-attribees thead th {
  vertical-align: middle;
}

table.table-attribees tbody td {
  padding: 5px 3px !important;
  height: 50px;
  color: #3c3c3c;
}

.text-section h6,
.revision-text h6 {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}

.line-separator {
  border-top: 1px solid #e0e0e0;
  margin: 20px 0;
}

.modification-section {
  display: flex;
  flex-direction: column;
}

.modification-title {
  font-weight: bold;
  color: #007bff;
}

.text-style {
  /* Additional styling if needed */
}

.iframe-container iframe {
  width: 100%;
  height: 500px;
  border: none;
}

@media (max-width: 768px) {
  .modal-body {
    padding: 10px;
  }
}

.rating,
.review,
.recommendation,
.user-info,
.terms,
.submit {
  margin-bottom: 20px;
}

.review input,
.review textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.review label {
  display: block;
  margin-bottom: 5px;
}

.review textarea {
  width: 100%;
  height: 100%;
}

.download-review button {
  background-color: #ffe426;
  color: #3c3c3c;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
