.icon-notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-notification-badge {
  position: absolute;
  top: -8px;
  right: 20px;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-notification-badge-nav {
  position: absolute;
  top: -8px;
  right: -10px;
  width: 25px;
  height: 25px;
  color: white;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-notification-badge .bg-red {
  background: red !important;
}

.icon-notification-badge .bg-orange {
  color: #ff8d4b !important;
}

.icon-notification-badge .bg-green {
  color: #43b929 !important;
}

.icon-notification-badge .bg-black {
  color: #3c3c3c !important;
}

.icon-notification-table--container {
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  position: relative;
  flex-grow: 1;
  min-width: 0;
  box-sizing: border-box;
  min-height: 0;
  z-index: 0;
}

.icon-notification-wrap {
  position: absolute;

  left: 20px;
  display: flex;
  justify-content: centers;
  align-self: center;
  position: relative;
  margin-bottom: 8px;
  margin-left: 12px;
  margin-top: 8px;
  box-sizing: border-box;
}

.icon-notification-table {
  width: 12px;
  height: 12px;
  background: hsl(214, 100%, 59%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.text-gray {
  color: #b0b3b8 !important;
}
