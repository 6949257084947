.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}
.box-list-header {
  display: inline-flex;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.box-list-header > .box-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
}
.box-table {
  max-height: 540px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box-table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

table.table-lists {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  vertical-align: middle;
}

table.table-lists th {
  background-color: #fff;
  border-left: 0px;
  text-align: center;
  color: #828282;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  letter-spacing: -0.02em;
  border-bottom: solid 1px #eee;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-lists thead th:first-child {
  border-left: 1px solid transparent;
  width: 55%;
  text-align: left;
}

table.table-lists tbody tr td:first-child {
  border-top-left-radius: 12px !important;
  border-left: 1px solid transparent;
  border-bottom-left-radius: 12px !important;
  text-align: left;
  color: #3c3c3c;
}

table.table-lists tbody tr td:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

table.table-lists td {
  background: #f5f5f5;
  border-left: 0px;
  text-align: center;
  color: #000;
  vertical-align: middle;
  font-size: 16px;
}

table.table-lists td i {
  font-weight: bold;
}

table.table-lists tr.bg-yellow td,
table.table-lists tr:hover td {
  background-color: rgba(255, 228, 38, 0.4) !important;
}

table.table-lists tr.bg-yellow td > span.bt-circle-wh,
span.bt-circle-wh:hover {
  background-color: white;
  display: inline-flex;
  width: 32px;
  height: 32px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
