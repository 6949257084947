.box-header-top {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

button {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}

.header-info h1 {
  font-size: 22px;
}

.nav-tabs-ct {
  border: 0px !important;
}

.nav-tabs-ct .nav-item.show .nav-link,
.nav-tabs-ct .nav-link.active,
.nav-tabs-ct .nav-link {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  border: 0px;
  padding: 0px;
  margin-right: 15px;
  margin-bottom: 8px;
  height: 42px;
}

.nav-tabs-ct .nav-link.active {
  border-bottom: solid 4px #ffe426;
}

.box-text-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin-bottom: 8px;
}

.box-text-title > .box-ch {
  flex: 1;
  display: inline-flex;
  align-items: center;
}

.box-text-title > .box-ch span {
  font-size: 20px;
  font-weight: 500;
  margin-right: 5px;
}

.white-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: white;
  color: #3c3c3c;
  border-radius: 48px;
}

.white-input-error {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: white;
  border-radius: 48px;
  color: #ee3900 !important;
}

.white-input:focus {
  outline: none;
}

.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr input {
  border-right: 0px !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl input {
  border-left: 0px !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  padding-left: 0px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.box-wrap,
.box-wrap-sm {
  position: relative;
  /*max-height: 463px;*/
  /*max-height: 463px;*/
  max-height: 530px;
  overflow: auto;
}

.box-wrap-sm {
  max-height: 350px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box-wrap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box-wrap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.box-wrap-sm {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.box-wrap-sm::-webkit-scrollbar {
  width: 3px;
}

.box-wrap-sm::-webkit-scrollbar-thumb {
  background-color: #ffe426; /* Adjust the scrollbar thumb color */
  border-radius: 10px; /* Adjust the border radius of the scrollbar thumb */
}

.box-rectangle {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 22px 20px 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
}

.box-flex-cols,
.box-flex-div,
.box-list-ui {
  display: flex;
  align-items: center;
}

.box-flex-cols {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.box-flex-cols div:first-child,
.box-flex-div > .col-tabs {
  flex: 1;
}

.box-flex-line {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-flex-line div:first-child {
  width: 50px;
}

.menu-list {
  width: 221px !important;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 0px;
  padding: 0px !important;
}

.menu-list > li > .dropdown-item {
  font-size: 16px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #3c3c3c;
}

.menu-list > li > .dropdown-item:focus,
.menu-list > li > .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
}

.menu-list > li.bg-yellow {
  background-color: #ffe426;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list > li.bg-light-yellow {
  background-color: rgba(255, 228, 38, 0.4);
}

.bt-check {
  float: right;
}

.box-list-n {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  border-bottom: solid 1px #eee;
  padding: 0px 8px 8px 0px;
}

.box-list {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  border-bottom: solid 1px #eee;
  padding: 0px 8px 8px 0px;
}

.box-list-li {
  display: block;
  margin-top: 10px;
}

.box-list div:first-child {
  width: 80%;
  word-wrap: break-word;
  word-break: break-word;
}

.box-list div:last-child {
  width: 20%;
}

.bg-page-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  color: #3c3c3c;
}

table.table-form thead th:first-child {
  width: 24%;
}

table.table-form tbody td {
  padding: 5px !important;
}

table.table-form tbody td:first-child {
  padding-left: 10px !important;
}

.wd-100 {
  width: 100px;
  margin: 0 auto;
}

.wd-80 {
  width: 80px;
  margin: 0 auto;
}

.wd-175 {
  width: 175px;
}

.box-text-ellipsis {
  overflow: hidden;
  display: block;
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-text-ellipsis:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

/*star rate*/

.box-star-list {
  display: flex;
  align-items: center;
}

.box-line-star {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 15px;
}

.box-line-star span {
  font-size: 16px;
  color: #828282;
}

.rating {
  display: inline-block;
  padding: 0px;
  margin-left: 5px;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position: absolute;
  /*top: -9999px;*/
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  padding: 0 0.1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 22px;
  line-height: 1.2;
  color: #ddd;
  /*text-shadow: 1px 1px #bbb, 2px 2px #666, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating:not(:checked) > label:before {
  content: '★ ';
}

.rating > input:checked ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px #c60, 2px 2px #940, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}
