.box-list-header > .box-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
}

.box-input-text {
  width: 63%;
  margin-right: 10px;
}

.input-cs > .input-group-text,
.input-cs input {
  background: #f5f5f5;
  border: 0px;
}

.input-cs > .input-group-text {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  padding: 0px 15px;
  font-size: 18px;
  color: #828282;
}

.input-cs input {
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  padding: 8px;
}

.input-cs > .input-group-text {
  border: 0px;
}

.input-cs input::placeholder {
  color: #828282 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-cs input:focus {
  background: #f5f5f5 !important;
  border: none !important;
  outline: 0;
  box-shadow: unset !important;
}

.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.input-password > .input-group-text {
  background-color: #fff !important;
  cursor: pointer;
}
