.box-page {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.box-page div:first-child {
  flex: 1;
}

.box-wrapper-cols {
  display: flex;
}

.box-wrapper-cols > .cols {
  width: 150px;
}

.box-list-check {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 24px;
  font-size: 16px;
  color: #3c3c3c;
}

.box-list-check input {
  margin-right: 10px;
  margin-top: 0px;
}

.box-list-check input:focus {
  box-shadow: none !important;
  border: solid 1px #e0e0e0 !important;
}

.box-checkbox input {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ced4da;
  background-color: #fff;
  cursor: pointer;
}

.box-checkbox > .form-check-input:checked[type='checkbox'] {
  background-image: url('../../../../public/Images/icon-check.png') !important;
  background-position: center;
  background-size: 16px;
}

.box-checkbox > .form-check-input:checked {
  background-color: #ffe426;
  border-color: #ced4da !important;
}

/* .CTA_validation {
  width: 192px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;

  margin: 30px 88px 0 119px;
  padding: 14px 18px;
  border: none;

  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #43b929;
} */

.bt-submit-y {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
}

.bt-submit-disabled-y {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #bdbdbd;
}

.text-h {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #3c3c3c;
}

.box-fr {
  display: flex;
  align-items: center;
  justify-items: center;
}

.box-ui-top {
  margin-bottom: 15px;
}

.box-ui-top,
.txt-title-top {
  display: flex;
  align-items: center;
}

.box-ui-top > .txt-title-top {
  flex: 1;
}
